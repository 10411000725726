<template>
  <v-row justify="center" align="center">
    <v-col md="6" sm="8" xs="12">
      <div :class="flipped ? 'flip-container flipped' : 'flip-container'">
        <div class="flipper">
          <div class="front">
            <slot name="front"></slot>
            <v-card>
              <v-card-title>
                Login
              </v-card-title>

              <v-card-text>
                <!-- Offline instruction -->
                <div v-show="!networkOnLine" data-test="offline-instruction">
                  Please check your connection, login feature is not available
                  offline.
                </div>

                <!-- Auth UI -->
                <p v-if="loginError" class="red--text">{{ loginError }}</p>
                <v-form
                  v-show="!user && networkOnLine"
                  ref="form"
                  v-model="valid"
                  @submit.prevent="login"
                  @keyup.native.enter="valid && login()"
                >
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-email"
                    :rules="emailRules"
                    label="Email"
                    required
                    data-lpignore="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    prepend-icon="mdi-lock"
                    label="Password"
                    :rules="requiredRule"
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    data-lpignore="true"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <v-card-actions>
                    <v-btn
                      data-test="login-btn"
                      :disabled="!valid || loading"
                      color="primary"
                      :loading="loading"
                      class="mr-4"
                      @click="login"
                    >
                      Login
                    </v-btn>
                    <v-btn color="info" class="mr-4" @click="flipped = true">
                      Reset Password
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </div>

          <div class="back">
            <slot name="back"></slot>
            <v-card>
              <v-card-title>
                Password Reset
              </v-card-title>
              <v-card-text>
                <v-form ref="password-reset" v-model="password_reset_valid">
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-email"
                    :rules="emailRules"
                    label="Email"
                    required
                    data-lpignore="true"
                    @keydown.enter.prevent
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :disabled="!password_reset_valid || passwordResetLoading"
                  color="green white--text"
                  :loading="passwordResetLoading"
                  class="mr-4"
                  @click="reset_password"
                >
                  Send reset email
                </v-btn>
                <v-btn @click="flipped = false">
                  Login
                </v-btn>
                <span style="color:red; margin-left: 5px">{{
                  passwordResetError
                }}</span>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <!-- flipper -->
      </div>
      <!-- flipped -->
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
// import { desktop as isDesktop } from 'is_js'

export default {
  data: () => ({
    loginError: null,
    passwordResetError: null,
    loading: false,
    passwordResetLoading: false,
    valid: false,
    password_reset_valid: false,
    email: '',
    password: '',
    requiredRule: [v => !!v || 'This field is required'],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Please enter a valid email'
    ],
    showPassword: false,
    flipped: false
  }),
  head() {
    return {
      title: {
        inner: 'Login'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/products'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    login() {
      this.loginError = null
      this.setUser(undefined)
      this.loading = true
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .catch(err => {
            this.loginError = err.message
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    reset_password() {
      if (this.$refs['password-reset'].validate()) {
        this.passwordResetLoading = true
        this.passwordResetError = null
        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .catch(err => {
            this.passwordResetError = err.message
          })
          .finally(() => {
            this.passwordResetLoading = false
          })
      }
    }
  }
}
</script>

<style scoped>
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  transform: perspective(1000px);
  transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>
